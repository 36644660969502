import * as JsSIP from "jssip";
import AudioElements from "../audioElements";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {apiConfig} from "../../config";
import {fakeAuthProvider} from "../auth/auth";
import {useNavigate} from "react-router-dom";
import * as Sentry from "@sentry/react";


const CONFIG = {
    uri: '1113@webrtc4.getret.ru',
    password: 'cilfvci904',
    sockets: [new JsSIP.WebSocketInterface('wss://webrtc4.getret.ru/ws')]
}
window.INCOME_SIP_SESSION = new JsSIP.UA(CONFIG);
const LISTENERS = {
    NEW_RTC_SESSION: 'newRTCSession',
    INCOMING: 'incoming',
    ACCEPTED: 'accepted',
    REGISTERED: 'registered',
    DISCONNECTED: 'disconnected',
    UNREGISTERED: 'unregistered',
    CONFIRMED: 'confirmed',
    ENDED: 'ended',
    FAILED: 'failed',
    PEER_CONNECTION: 'peerconnection',
    ADD_STREAM: 'addstream',
    REGISTRATION_FAILED: 'registrationFailed',
    REGISTRATION_EXPIRED: 'registrationExpiring',
    CONNECTING: 'connecting',
    CONNECTED: 'connected'
}

const INCOME_CALL_MEDIA_CONSTRAINTS_OPTIONS = {
    audio: true,
    video: false,
};

export const IncomeCall = ({isCallActive, selectedStatus, setSelectedStatus, audio1, audio2}) =>{
    const [inCall, setInCall] = useState(null)
    const [inCall2, setInCall2] = useState(null)
    const [updatingStatus, setUpdatingStatus] = useState(false)
    const [checkStatus, setCheckStatus] = useState(false)
    const [checkCanceled, setCanceled] = useState(false)
    const [samoletCall, setSamoletCall] = useState(false)
    const [waitLeadTime, setWaitLeadTime] = useState(20)

    const [waiTime, setWaitTime] = useState(0);
    const [userContact, setUserContact] = useState('');
    const [playing, setPlaying] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (inCall === null) {
            return
        }
        let duration
        let audio

        if (inCall === true) {
            duration = 500

            console.log("START PLAY BIP")
            audio1.load()
            audio1.play()

            audio = audio1
        }
        else {
            duration = 1000

            console.log("START PLAY BIP")
            audio2.load()
            audio2.play()

            audio = audio2
        }

        setTimeout(() => {
            audio.pause()
            console.log("PAUSE", inCall)

            setInCall(null)
            }, duration);

    }, [inCall])

    const confirmCall = () => {
        if (window.session) {
            window.session.answer(INCOME_CALL_MEDIA_CONSTRAINTS_OPTIONS);
            console.log("ANSWER")
            console.log(window.session,'window.session')

            setSamoletCall(false)

            window.session.connection.addEventListener('addstream', (e) => {
                var audio = document.createElement('audio');
                audio.srcObject = e.stream;
                audio.volume = Math.min(1, audio.volume + 2);
                audio.play();
            });

            if (window.client && window.client.readyState !== 0) {
                window.client.send("update_status")
            }
        }
    }

    const newRTCSessionCallBack = (event) => {
        console.log("INCOME CALL")

        if (window.session) {
            console.log("INCOME CALL WHILE HAS CALL")
            event.session.terminate()

            return
        }
        window.session = event.session

        if (window.session.direction === LISTENERS.INCOMING) {
            confirmCall()

            window.session.on(LISTENERS.ACCEPTED, (e) => {
                console.log("ACCEPTED")
            })

            window.session.on(LISTENERS.CONFIRMED, (e) => {
                console.log("CONFIRMED")
                console.log("ESTABLISHED", window.session.isEstablished())

                getLeadUrl()

                // setTimeout(() => {
                if (window.session) {
                    console.log("мои поздравления, ты принял звонок")

                    let call_id = window.session._request.call_id;
                    sendCallId(call_id)
                    setInCall(true)
                    setInCall2(true)
                    window.set_confirmed = true

                    // let lead_url = localStorage.getItem("lead_url")
                    // if (!lead_url) {
                    //     getLeadUrl().then(r => {
                    //         console.log(r)
                    //         window.open(r.replace("{{DNIS}}", window.session._request.from.uri.user), "_blank")
                    //     })
                    // } else {
                    //     window.open(lead_url.replace("{{DNIS}}", window.session._request.from.uri.user), "_blank")
                    // }
                }
                else {
                    console.log("КОСТЫЛЬ СРАБОТАЛ, АХЕРЕТЬ (звонок был принят другим оператором)")
                    Sentry.captureMessage(
                        `NO SESSION IN CONFIRM ${localStorage.getItem("user_name")}`
                    );
                }
                // }, 1000);
            })

            window.session.on(LISTENERS.ENDED, (e) => {
                console.log("LISTNER ENDED")
                window.session = null

                // if (window.set_confirmed) {
                setInCall(false)
                setInCall2(false)
                window.set_confirmed = false
                setSamoletCall(false)
                // setCanceled(true)

                setSelectedStatus("Заполнение лида")
                // }
            })

            window.session.on(LISTENERS.FAILED, (e) => {
                console.log("LISTNER FALED")
                Sentry.captureMessage(
                    `LISTNER FALED ${localStorage.getItem("user_name")} ${window.session ? window.session._request.from._uri._user : null}`
                );

                window.session = null
                // if (inCall !== null) {
                //     setInCall(false)
                //     setSamoletCall(false)
                // }
                //
                // setInCall2(false)

                setInCall(false)
                setInCall2(false)
                window.set_confirmed = false
                setSamoletCall(false)
                // setCanceled(true)

                setSelectedStatus("Заполнение лида")
            })

            window.session.on(LISTENERS.PEER_CONNECTION, (e) => {
                console.log("PEER CONNECTION")
            })

        } else {
            window.session.connection.addEventListener(LISTENERS.ADD_STREAM, (e) => {
                console.log("ADD_STREAM")
            });
        }
    }

    const  sendCallStatus = async (status)=> {
        setUpdatingStatus(true)
        window.updating_status = true
        const config = {
            method: 'patch',
            url: `${apiConfig.url}/auth/status?status=${status}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            if (window.client && window.client.readyState !== 0) {
                window.client.send("update_status")
            }
            console.log("UPDATING STATUS", response)
            setUpdatingStatus(false)
            window.updating_status = false

        } catch (error) {
            console.log("TOKEN", localStorage.getItem('token_caltatToki'))
            console.error('error', error);

            await fakeAuthProvider.signout();
            navigate(0)
            setUpdatingStatus(false)
            window.updating_status = false

            return navigate("/");
        }
    }

    const UpdateContactUri = async (contact_uri)=> {
        const config = {
            method: 'patch',
            url: `${apiConfig.url}/auth/contact-uri?contact_uri=${contact_uri}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        const response = await axios(config);
        console.log(response)
    }

    const Verify = async () => {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/auth/verify`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            console.log("VERIFY", response.data)
            return response.data.projects
        } catch (error) {
            console.error('error', error);
            await fakeAuthProvider.signout();
            navigate(0)
            return navigate("/");
        }
    }

    const VerifyStatus = async () => {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/auth/verify`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);

            let status_from_server = response.data.status
            let statuses_dict = {
                'Отошел': 'MOVED_AWAY',
                'Готов': 'READY',
                'Звонок': 'IN_CALL',
                'Заполнение лида': 'WAIT_LEAD',
                'Обучение': 'EDUCATION',
            }
            console.log(statuses_dict[window.user_status], status_from_server)
            if (statuses_dict[window.user_status] !== status_from_server) {
                if (statuses_dict[window.user_status] === 'READY' && status_from_server === 'MOVED_AWAY') {
                    Sentry.captureMessage(
                        `WRONG STATUS READY ${localStorage.getItem("user_name")}`
                    );
                    console.log("STATUS FROM SERVER SHIT", statuses_dict[window.user_status])
                    ReadyStatusReaction({
                            waiTime,
                            setWaitTime,
                            UpdateContactUri,
                            sendCallStatus,
                            setUserContact,
                            newRTCSessionCallBack,
                            LISTENERS,
                            CONFIG
                        }
                    )
                }
                if (status_from_server === 'IN_CALL' && window.session == null) {
                    console.log("STATUS FROM SERVER SHIT IN CALL", statuses_dict[window.user_status])
                    Sentry.captureMessage(
                        `WRONG STATUS IN_CALL ${localStorage.getItem("user_name")}`
                    );
                    sendCallStatus(statuses_dict[window.user_status])
                }
                if (status_from_server === 'OFFLINE') {
                    console.log("STATUS FROM SERVER SHIT OFFLINE", statuses_dict[window.user_status])
                    Sentry.captureMessage(
                        `WRONG STATUS OFFLINE ${localStorage.getItem("user_name")}`
                    );
                    if (statuses_dict[window.user_status] === "READY") {
                        if (window.INCOME_SIP_SESSION) {
                            window.INCOME_SIP_SESSION.unregister();
                            window.INCOME_SIP_SESSION.stop()
                        }

                        ReadyStatusReaction({
                                waiTime,
                                setWaitTime,
                                UpdateContactUri,
                                sendCallStatus,
                                setUserContact,
                                newRTCSessionCallBack,
                                LISTENERS,
                                CONFIG
                            }
                        )
                    }
                    else {
                        sendCallStatus(statuses_dict[window.user_status])
                    }
                }
            }

            setCheckStatus(false)

        } catch (error) {
            console.error('error', error);
            await fakeAuthProvider.signout();
            navigate(0)
            return navigate("/");
        }
    }

    const getLeadUrl = async ()=> {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project/lead/call`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            console.log("LEAD BY CALL", response)
            setWaitLeadTime(response.data.wait_lead_time)
            window.open(`${response.data.lead_url}&t=${localStorage.getItem('token_caltatToki')}`)

        } catch (error) {
            console.error('error', error);

            // await fakeAuthProvider.signout();
            // navigate(0)
            // return navigate("/");
        }
    }

    const  sendCallId = async (call_id)=> {
        const config = {
            method: 'post',
            url: `${apiConfig.url}/calls/answer-info?protocol_id=${call_id}`,
        };
        try {
            const response = await axios.post(config.url, null, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
                }
            });

            console.log(response)
        } catch (error) {
            console.error('error', error);

            await fakeAuthProvider.signout();
            navigate(0)
            return navigate("/");
        }
    }

    useEffect( () => {
        window.user_status = selectedStatus

        if (selectedStatus === 'Заполнение лида') {
            setWaitTime(waitLeadTime)
        }

        if (selectedStatus === 'Готов') {
            ReadyStatusReaction({
                    waiTime,
                    setWaitTime,
                    UpdateContactUri,
                    sendCallStatus,
                    setUserContact,
                    newRTCSessionCallBack,
                    LISTENERS,
                    CONFIG
                }
            )

        } else {
            if (selectedStatus === 'Отошел') {
                sendCallStatus('MOVED_AWAY')
                if (window.session) {
                    window.session.terminate();
                }
                if (window.INCOME_SIP_SESSION) {
                    window.INCOME_SIP_SESSION.unregister();
                    window.INCOME_SIP_SESSION.stop()
                }
                window.canUseListener = true
            }
            else if (selectedStatus === 'Обучение') {
                sendCallStatus('EDUCATION').then(() => {
                    if (window.session) {
                        window.session.terminate();
                    }
                    if (window.INCOME_SIP_SESSION) {
                        window.INCOME_SIP_SESSION.unregister();
                        window.INCOME_SIP_SESSION.stop()
                    }
                })
            }
            else {
                sendCallStatus('WAIT_LEAD')
                if (window.INCOME_SIP_SESSION) {
                    window.INCOME_SIP_SESSION.unregister();
                    window.INCOME_SIP_SESSION.stop()
                }
            }

        }
    },[selectedStatus])

    // Создаем эффект, который запускает таймер при монтировании компонента
    useEffect(() => {
        // Если время не истекло, устанавливаем интервал для обновления времени каждую секунду
        if (waiTime > 0 && selectedStatus === 'Заполнение лида') {
            const timerId = setInterval(() => {
                setWaitTime(waiTime - 1);
            }, 1000);
            // Возвращаем функцию очистки, которая очищает интервал при размонтировании компонента
            return () => {
                clearInterval(timerId);
            };
        } else if (selectedStatus === 'Заполнение лида') {
            console.log('Время вышло!');
            setSelectedStatus('Готов')
        }
    }, [waiTime]);

    const [t, setT] = React.useState(0);
    useEffect(() => {
        // setInterval(() => {
        //     if (checkStatus === false) {
        //         setCheckStatus(true)
        //         console.log("CHECKER STATUS")
        //         VerifyStatus()
        //     }
        // }, 2000);
        const timer = window.setTimeout(() => {
            // if (checkStatus === false) {
            setT(t+1)
            // setCheckStatus(true)
            console.log("CHECKER STATUS", window.user_status)
            if (!window.updating_status) {
                VerifyStatus()
            }
            // }
        }, 4000);

        return () => window.clearInterval(timer);
    }, [t])

    console.log("BB")

    return (
        <div style={{display: "flex", flex: 1, flexFlow: "column", alignItems: "center"}}>
            <div
                style={{display: "flex", textAlign: "center", fontSize: 25, marginBottom: 30, flexDirection: "column"}}>
                {inCall2 ? <h3>Звонок идет</h3> : <h3>Звонка нет</h3>}
                <div>
                    {inCall2 ? <Timer/> : null}
                </div>
            </div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <button
                    className="col-span-3 bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => {
                        inCall2 ? window.session.terminate() : console.log("no session");
                        window.session = null

                        // if (inCall) {
                        //     window.INCOME_SIP_SESSION.stop()
                        //     sendCallStatus("WAIT_LEAD")
                        //     setSelectedStatus("Заполнение лида")
                        // }
                    }}
                    style={{display: "flex"}}
                >
                    Завершить звонок
                </button>
            </div>
            {waiTime ? (
                <div style={{marginTop: 20}}>
                    Ожидание заполнения лида: {waiTime}
                </div>
            ) : null
            }
            <div style={{marginTop: 40}}>
                <button
                    onClick={() => {
                        if (!samoletCall) {
                            var options = {
                                'duration': 100,
                                'interToneGap': 200
                            };
                            if (window.session) {
                                window.session.sendDTMF('*27', options);
                                console.log("PEREVOD")
                                setSamoletCall(true)
                            }
                        }
                    }}
                    style={{
                        width: 150,
                        height: 50,
                        color: "white",
                        backgroundColor: "rgb(27, 170, 42)"
                    }}>
                    Перевести на самолет
                </button>
            </div>
            <div style={{marginTop: 40}}>
                <button
                    onClick={() => {
                        if (!samoletCall) {
                            var options = {
                                'duration': 100,
                                'interToneGap': 200
                            };
                            if (window.session) {
                                window.session.sendDTMF('*28', options);
                                console.log("PEREVOD")
                                setSamoletCall(true)
                            }
                        }
                    }}
                    style={{
                        width: 150,
                        height: 50,
                        color: "white",
                        backgroundColor: "rgb(27,165,170)"
                    }}>
                    Перевести в пик МОСКВА
                </button>
            </div>
            <div style={{marginTop: 40}}>
                <button
                    onClick={() => {
                        if (!samoletCall) {
                            var options = {
                                'duration': 100,
                                'interToneGap': 200
                            };
                            if (window.session) {
                                window.session.sendDTMF('*29', options);
                                console.log("PEREVOD")
                                setSamoletCall(true)
                            }
                        }
                    }}
                    style={{
                        width: 150,
                        height: 50,
                        color: "white",
                        backgroundColor: "rgb(170,141,27)"
                    }}>
                    Перевести в пик ПИТЕР
                </button>
            </div>
        </div>
    )
}

const ReadyStatusReaction = (props) => {
    window.updating_status = true

    // window.INCOME_SIP_SESSION = new JsSIP.UA(props.CONFIG);
    console.log("SIP_SES", window.INCOME_SIP_SESSION)

    console.log("events", window.INCOME_SIP_SESSION.eventNames())
    if (window.INCOME_SIP_SESSION.eventNames().length === 0) {
        window.INCOME_SIP_SESSION.on(props.LISTENERS.NEW_RTC_SESSION, props.newRTCSessionCallBack)

        window.INCOME_SIP_SESSION.on(props.LISTENERS.REGISTERED, (event) => {
            console.log("REGISTERED")
            let from = event.response.from.uri.user
            let user = window.INCOME_SIP_SESSION.contact.uri["user"]
            let received_host = event.response["via"]["received"]
            let rport = event.response["via"]["rport"]
            let host = event.response["via"]["host"]

            let contact = `PJSIP/${from}/sip:${user}@${received_host}:${rport};transport=ws;x-ast-orig-host=${host}:0`
            console.log(contact)
            props.setUserContact(contact)
            props.UpdateContactUri(contact).then(() => {
                props.sendCallStatus('READY')
            })
        })
    }

    window.INCOME_SIP_SESSION.start()
    console.log("START")

    if (props.waiTime > 0) {
        props.setWaitTime(0)
    }

    console.log("END READY")
}

const Timer = () => {
    const [time, setTime] = useState(0);

    // state to check stopwatch running or not
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {
        let intervalId;
        if (isRunning) {
            // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
            intervalId = setInterval(() => setTime(time + 100), 1000);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, time]);

    // Hours calculation
    const hours = Math.floor(time / 360000);

    // Minutes calculation
    const minutes = Math.floor((time % 360000) / 6000);

    // Seconds calculation
    const seconds = Math.floor((time % 6000) / 100);

    // Milliseconds calculation
    const milliseconds = time % 100;

    // Method to start and stop timer
    const startAndStop = () => {
        setIsRunning(!isRunning);
    };

    // Method to reset timer back to 0
    const reset = () => {
        setTime(0);
    };
    return (
        <div style={{fontSize: 25}}>
            <p className="stopwatch-time">
                {hours}:{minutes.toString().padStart(2, "0")}:
                {seconds.toString().padStart(2, "0")}
            </p>
        </div>
    );
};

{/*<AudioElements*/}
{/*    {...{*/}
{/*        ref: audioRef,*/}
{/*    }}*/}
{/*/>*/}

